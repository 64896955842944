<template>
  <div class="section">
    <div class="container">
      <h1 class="title has-text-centered">Sign In</h1>
      <div class="mb-4">
        <AuthInput
          :label="'username'"
          :icon="'fa-user'"
          :error="error"
          v-model:value="username"
        />
        <AuthInput
          :label="'password'"
          :icon="'fa-key'"
          :error="error"
          v-model:value="password"
        />
        <p :v-if="!error.field" class="help is-danger">{{ error.error }}</p>
      </div>
      <button
        @click="submit"
        class="button is-info"
        :class="{ 'is-loading': isLoading }"
      >
        Submit
      </button>
      <span
        ><p>need to create an account?</p>
        <button
          @click="this.$router.push('/register')"
          class="button is-light is-link right"
        >
          Register
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import AuthInput from "@/components/AuthInput.vue";
import axios from "axios";

export default {
  components: { AuthInput },
  name: "SignIn",
  data() {
    return {
      email: "",
      username: "",
      password: "",
      error: {
        field: "",
        message: "",
      },
      isLoading: false,
      user: null,
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await axios
        .post(
          `${this.$store.state.hostname}/api/v1/signin`,
          {
            username: this.username,
            password: this.password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          this.$store.commit("set_user", response.data);
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.error = { error: "server error" };
          } else if (error.response) {
            this.error = error.response.data;
          }
        });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 500px !important;
}
.right {
  float: right;
}
span {
  float: right;
  display: flex;
  align-items: center;
}
p {
  padding-right: 5px;
}
</style>
