<template>
  <footer class="footer">
    <div class="content">
      <p>Created by Cooper Marshall with &#128154;</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  min-height: 10vh;
}
</style>
